<template>
    <el-dialog v-model="dialogVisible" title="批量导入" :close-on-click-modal="false" width="1020px">
        <div class="confirmbox">
            <el-table :data="state.Notice" class="mb-20" :header-cell-style="{
                background: '#EAEAEA',
                color: config.table.color,
                fontWeight: config.table.fontWeight,
                fontSize: config.table.fontSize,
            }" row-key="id">
                <el-table-column :align="'center'" type="index" label="序号" width="80" />
                <el-table-column :align="'center'" prop="name" label="姓名" />
                <el-table-column :align="'center'" prop="code" label="联系方式" />
            </el-table>
        </div>
        <template #footer>
            <span class="dialog-footer dp-f fd-rr">
                <oabutton class="searcML" width='120' height='40' title="导入" CSStype=2 @buttonclick="submit()"
                    v-model:loading="dialogloading"></oabutton>
                <oabutton class="searcML" width='120' height='40' title="重新上传" CSStype=3 @buttonclick="dialogVisible = false">
                </oabutton>
            </span>
        </template>
    </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits } from 'vue'
import { httpToken } from "@/utils/request";
import qs from "qs";
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const state = reactive({
    Notice: []
})
const getData = ((data) => {
    state.Notice = data
    dialogVisible.value = true
})
// 数据提交
const submit = (() => {
    dialogloading.value=true
    httpToken({
        method: "post",
        url: '/admin/addressbook/addMany',
        data:state.Notice,
    }).then((res) => {
        dialogloading.value=false
        dialogVisible.value = false
        emit('Emit')
        // console.log('批量导入',res)
    }).catch(()=>{
        dialogloading.value=false
    })
})
defineExpose({
    getData
});
</script>
<style lang="scss" scoped >
.confirmbox {
    ::v-deep .el-table .el-table__cell {
        height: 50px;
    }
}
</style>